import React from 'react';
import Link from 'next/link';
import { Link  as LinkScroll} from 'react-scroll';
import * as Icon from 'react-feather';
import parse from 'html-react-parser';
import { useRouter } from 'next/router'
import AppContext from '../../AppContext'
import { useContext } from "react";

const Footer = ({info,social,desc,isHome}) => {
    info = (info == null)?{
        Email : "",
        Telp :"",
        Address : "",
    }:info;
    isHome = (isHome == null)?true:isHome;
    const context = useContext(AppContext);
    const currentYear = new Date().getFullYear();
    const router = useRouter();
    const Socials = (social)?social.map((data,idx)=>{
        return(
            <li key={idx}>
                <Link href={data.attributes.Link}>
                    {
                        (data.attributes.Type =='Facebook')
                        ?(<a className="facebook" target="_blank"><Icon.Facebook /></a>)
                        :(data.attributes.Type =='Instagram')
                        ?(<a className="instagram" target="_blank"><Icon.Instagram /></a>)
                        :(data.attributes.Type =='Twitter')
                        ?(<a className="twitter" target="_blank"><Icon.Twitter /></a>)
                        :(data.attributes.Type =='Youtube')
                        ?(<a className="youtube" target="_blank"><Icon.Youtube /></a>):""
                    }
                </Link>
            </li>
        );
    }):"";

    return (
        <footer className="footer-area bg-f7fafd">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="single-footer-widget">
                            <div className="logo">
                                <Link offset={100} href="/it-startup">
                                    <img src="/images/TBG-Home/White.png" width="250px" alt="logo" />
                                </Link>
                            </div>
                            <p>{parse(desc)}</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Menu</h3>
                            <ul className="list">
                            {(isHome)
                                ?(
                                    <>
                                        <li>
                                            <LinkScroll to="home" spy={true} offset={0} smooth={true}>
                                                Home
                                            </LinkScroll>
                                        </li>
                                        <li>
                                            <LinkScroll offset={80} to="business_partner" spy={true} smooth={true}>
                                                Our Business
                                            </LinkScroll>
                                        </li>
                                        <li>
                                            <LinkScroll offset={80} to="industri" spy={true} smooth={true}>
                                                Industry
                                            </LinkScroll>
                                        </li> 
                                        <li>
                                            <a href='/careers' target='_blank'>
                                                Careers
                                            </a>
                                        </li>
                                        <li>
                                            <LinkScroll offset={-80} to="gallery" spy={true} smooth={true}>
                                                Gallery
                                            </LinkScroll>
                                        </li>
                                        <li>
                                            <LinkScroll offset={-80} to="subscribe" spy={true} smooth={true}>
                                                Subscribe
                                            </LinkScroll>
                                        </li>
                                        {/* <li>
                                            <LinkScroll offset={-80} to="carrers" spy={true} smooth={true}>
                                                Careers
                                            </LinkScroll>
                                        </li> */}
                                        
                                    </>
                                )
                                :(
                                    <>
                                        <li>
                                            <Link href={{
                                                pathname:"/",
                                                query:{
                                                    "s":"home"
                                                }
                                            }}>
                                                <a>Home</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href={{
                                                pathname:"/",
                                                query:{
                                                    "s":"business_partner"
                                                }
                                            }}>
                                                <a>Businees Partner</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href={{
                                                pathname:"/",
                                                query:{
                                                    "s":"industri"
                                                }
                                            }}>
                                                <a>Industri</a>
                                            </Link>
                                        </li> 
                                        <li>
                                            <Link href={{
                                                pathname:"/",
                                                query:{
                                                    "s":"gallery"
                                                }
                                            }}>
                                                <a>Gallery</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href={{
                                                pathname:"/",
                                                query:{
                                                    "s":"subscribe"
                                                }
                                            }}>
                                                <a>Subscribe</a>
                                            </Link>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        {/* <div className="single-footer-widget">
                            <h3>Address</h3>
                            
                            <ul className="footer-contact-info">
                                <li> 
                                    <Icon.MapPin />
                                    {info.Address}
                                </li>
                                <li>
                                    <Icon.Mail />
                                    Email: <a href={`mailto:${info.Email}`}>{info.Email}</a>
                                </li>
                                <li> 
                                    <Icon.PhoneCall />
                                    Phone: <a href={`tel:${info.Telp}`}>{info.Telp}</a>
                                </li>
                            </ul>
                            <ul className="social-links">
                                {Socials}
                            </ul>
                        </div> */}
                    </div>

                    <div className="col-lg-12 col-md-12">
                        <div className="copyright-area">
                            <p>Copyright &copy; {currentYear} All Right Reserved by <a href="https://thamrin.co.id/" target="_blank">Thamrin Group</a></p>
                        </div>
                    </div>
                </div>
            </div>

            <img src="/images/map.png" className="map" alt="map" />

            {/* Shape Images */}
            <div className="shape1">
                <img src="/images/shape1.png" alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src="/images/shape2.svg" alt="shape" />
            </div>
        </footer>
    )
     
}

export default Footer; 